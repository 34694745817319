import { reactive, Ref } from "vue";
import useVuelidate, { Validation, ValidationArgs } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export interface Step2FormInterface {
  files: string[];
  recaptcha: string;
}

export interface FormInterface {
  v$: Ref<Validation<ValidationArgs>>;
  fields: Step2FormInterface;
}

const form = (): FormInterface => {
  const state = reactive<Step2FormInterface>({
    files: [],
    recaptcha: "",
  });

  const rules: ValidationArgs = {
    files: {
      required: helpers.withMessage("required", required),
    },
    recaptcha: {
      required: helpers.withMessage("required", required),
    },
  };

  const v$ = useVuelidate(rules, state);

  return { v$, fields: state };
};

export default form;
