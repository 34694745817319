import { ref } from "vue";

export function useLoader() {
  const isLoading = ref<boolean>(false);

  const addLoading = () => (isLoading.value = true);
  const removeLoading = () => (isLoading.value = false);

  return { isLoading, addLoading, removeLoading };
}
